import { inject } from '@angular/core';
import { tap } from 'rxjs';
import { HeaderService, PageTitleData } from './header.service';
import { PageTitleUpdater } from './page-title-updater';

export function titleResolver(pageTitle: PageTitleData) {
  return () => {
    const headerService = inject(HeaderService);
    const updater = inject(PageTitleUpdater);
    // give the translation key to the updater, this will make sure both headerService and titleService will be updated if language changed
    return updater.translatePageTitleData$(pageTitle).pipe(
      tap((translatedTitle) => {
        headerService.header.set({
          title: translatedTitle ?? null,
          badge: pageTitle.titleBadge ?? null,
          flavor: 'highlight',
        });
      }),
    );
  };
}
