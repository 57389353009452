import { Injectable, signal } from '@angular/core';
import { StatusBadgeFlavor } from '@cca-common/core';

export interface PageTitleData {
  title: string;
  titleBadge?: string | null;
  titleBadgeFlavor?: string | null;
  params?: Record<string, unknown>;
  translocoScope?: string;
}

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  readonly header = signal<{
    title: string | null;
    badge?: string | null;
    flavor?: StatusBadgeFlavor | null;
  } | null>(null);
}
